<template>
  <div class="wrap">
    <DetailContent
      @sendEmail="sendEmailHandler()"
      :orderData="showOrderData"
      v-if="showOrderData"
    ></DetailContent>
    <SendEmailResult
      v-if="openSendEmailResult"
      text="謝謝您的回報，我們將盡快為您處理"
      direction="/member/record"
    />
  </div>
</template>

<script>
import DetailContent from "../../components/會員相關/DetailContent";
import SendEmailResult from "../../components/SendEmailResult";
export default {
  name: "member-detail",
  components: { DetailContent, SendEmailResult },
  data() {
    return {
      showOrderData: null,
      openSendEmailResult: false,
    };
  },
  computed: {},
  mounted() {
    let orderId = parseInt(this.$route.params.orderId);
    if (orderId) {
      this.DetailHandler(orderId);
    } else {
      this.$router.push("/member/record");
    }
  },
  methods: {
    DetailHandler(orderId) {
      this.$API
        .Get_PurchaseRecord(orderId)
        .then(({ data }) => {
          this.showOrderData = data.Data;
        })
        .catch(() => {
          this.showOrderData = null;
        });
    },
    sendEmailHandler() {
      this.openSendEmailResult = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_mixin.scss";
.wrap {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 0;

  @include mobile {
    width: 100%;
    padding: 0 37px;
    margin: 0 auto;
  }
}
</style>
