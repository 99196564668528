var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _vm.showOrderData
        ? _c("DetailContent", {
            attrs: { orderData: _vm.showOrderData },
            on: {
              sendEmail: function($event) {
                return _vm.sendEmailHandler()
              }
            }
          })
        : _vm._e(),
      _vm.openSendEmailResult
        ? _c("SendEmailResult", {
            attrs: {
              text: "謝謝您的回報，我們將盡快為您處理",
              direction: "/member/record"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }